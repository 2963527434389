<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h3>
        {{ Object.entries(this.data).length === 0 ? "Add New" : "Edit" }}
        Content
      </h3>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="p-6">
        <label class="">Title</label>
        <v-select
          :options="titleOptions"
          :clearable="false"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="titleFilter"
          class="mb-4 md:mb-0"
        />
      </div>
      <div class="p-6">
        <div class="mb-2">
          <vs-input
            label="Full Name"
            v-model="fullName"
            class="w-full mb-5"
            name="fullName"
            v-validate="'required|alpha_spaces'"
            data-vv-as="Full Name"
          />
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{
            errors.first("fullName")
          }}</span>
        </div>
        <div class="mb-2">
          <vs-input
            label="Source from"
            v-model="sourceFrom"
            class="w-full mb-5"
            name="sourceFrom"
            v-validate="'required'"
            data-vv-as="Source from"
          />
          <span class="text-danger text-sm" v-show="errors.has('sourceFrom')">{{
            errors.first("sourceFrom")
          }}</span>
        </div>
        <label class="block mb-2">Description</label>
        <vs-textarea
          v-model="description"
          class="w-full"
          name="description"
          validate="required"
        />
        <span class="text-danger text-sm" v-show="errors.has('description')">{{
          errors.first("description")
        }}</span>

        <!--<vs-input label="Redirect Link" v-model="redirectLink" class="w-full mb-5" name="redirectLink" validate="required"/>
        <span class="text-danger text-sm" v-show="errors.has('redirectLink')">{{ errors.first('redirectLink') }}</span>
-->
        <div class="vx-col w-full mt-5 vs-con-loading__container">
          <template v-if="logo">
            <!-- Image Container -->
            <div
              class="img-container w-64 mx-auto flex items-center justify-center"
            >
              <img :src="logo" alt="img" class="responsive" />
            </div>

            <!-- Image upload Buttons -->
            <div class="modify-img flex justify-between my-5 edited-button">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button class="mr-4" @click="$refs.updateImgInput.click()"
                >Update Image</vs-button
              >
              <vs-button color="danger" @click="logo = null"
                >Remove Image</vs-button
              >
            </div>
          </template>

          <div class="upload-img edited-button my-5" v-if="!logo">
            <input
              type="file"
              class="hidden"
              ref="uploadImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button @click="$refs.uploadImgInput.click()"
              >Upload Image</vs-button
            >
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center justify-between p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid"
        >Submit</vs-button
      >
      <vs-button
        class="vs-button-secondary"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dataId: null,
      fullName: "",
      sourceFrom: "",
      description: "",
      // redirectLink:"",
      logo: null,
      image: null,
      settings: {
        // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      customError: "",
      titleOptions: [
        { label: "Mr.", value: "Mr." },
        { label: "Mrs.", value: "Mrs." },
        { label: "Miss.", value: "Miss." }
      ],
      titleFilter: { label: "Select Title", value: "" }
    };
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.dataId = this.data._id;
        this.fullName = this.data.fullName;
        this.sourceFrom = this.data.sourceFrom ? this.data.sourceFrom : "";
        this.$validator.reset();
        this.description = this.data.description;
        this.image = this.data.profileImage;
        this.logo = this.data.profileImage;
        if (this.data.title) {
          this.titleFilter = { label: this.data.title, value: this.data.title };
        }
        this.initValues();
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.fullName &&
        this.description &&
        // && this.redirectLink
        this.logo
      );
    }
  },
  methods: {
    ...mapActions("setting", ["updateTestimonial", "addTestimonial"]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    initValues() {
      if (this.data._id) return;
      this.dataId = null;
      this.titleFilter = { label: "Select Title", value: "" };
      this.fullName = "";
      this.description = "";
      this.sourceFrom = "";
      // this.redirectLink = ""
      this.logo = null;
      this.image = null;
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader();
        reader.onload = e => {
          this.logo = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append("id", this.dataId);
          data.append("title", this.titleFilter.value);
          data.append("fullName", this.fullName);
          data.append("sourceFrom", this.sourceFrom);
          data.append("description", this.description);
          // data.append('redirectLink', this.redirectLink);
          data.append("profileImage", this.image);
          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading();
            this.updateTestimonial(data)
              .then(res => {
                if (res.status === 200) {
                  this.showMessage(
                    "Success",
                    "Testimonial updated successfully.",
                    "success"
                  );
                  this.$emit("refreshData", true);
                }
                this.$vs.loading.close();
              })
              .catch(err => {
                this.$vs.loading.close();
                //console.error(err)
              });
          } else {
            if (this.logo == null) {
              this.customError = "Profile Image is required.";
              return;
            }
            this.$vs.loading();
            this.addTestimonial(data)
              .then(res => {
                if (res.status === 200) {
                  this.$vs.loading.close();
                  this.$emit("refreshData", true);
                  this.showMessage(
                    "Success",
                    "Testimonial added successfully.",
                    "success"
                  );
                }
              })
              .catch(err => {
                this.$vs.loading.close();
                if (err.response.status === "422") {
                  this.showMessage(
                    "Failed",
                    err.response.data.message,
                    "danger"
                  );
                }
                this.showMessage(
                  "Failed",
                  "Failed to add Testimonial",
                  "danger"
                );
                // console.error(err)
              });
          }
          this.$emit("closeSidebar");
          //this.initValues()
        }
      });
    }
  },
  created() {},
  components: {
    vSelect,
    VuePerfectScrollbar
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
