<template>
  <div>
    <vx-card>
      <TestimonialSidebar
        :isSidebarActive="isSidebarActive"
        @refreshData="toggleRefreshData"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
        :dataId = "dataId"
      />
      <div class="mb-8" >
          <h2 class="mb-0">Testimonial List</h2>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col md:w-1/2 w-full" align="left">
          <vs-input class="w-full search-input" v-model="searchQuery" placeholder="Search..."/>
        </div>
        <div class="vx-col md:w-1/2" align="right">
          <vs-button class="mb-md-0 mb-2"
                       align="right"
                       @click="addTestimonial"
            >Add Testimonial
            </vs-button>
        </div>
      </div>

      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="contents"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse flex-grow justify-between mb-5"
          >
            <div style="float: left">
              <vs-select
                placeholder="10"
                vs-multiple
                autocomplete
                v-model="dataTableParams.limit"
                class="mr-6"
                label="display per page"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in limitOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
          </div>

          <template slot="thead">
            <vs-th>FullName</vs-th>
            <vs-th class="action-col justify-center">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.fullName">
                {{ `${tr.title ? tr.title : ''} ${tr.fullName}`  | capitalize }}
              </vs-td>
              <vs-td :data="tr._id" class="text-center">
                <a href="#" @click.stop="editDetailHandler(tr, tr._id)">
                  <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"  />
                </a>
                <a href="#" @click.stop="deleteHandler(tr._id)">
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="m-2" v-if="contents.length > 0" >
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import vSelect from "vue-select";
  import { mapActions } from "vuex";
  import TestimonialSidebar from "./editTestimonialSidebar";
  import Swal from 'sweetalert2/dist/sweetalert2';
  import 'sweetalert2/src/sweetalert2.scss'

  export default {
    components: {
      "v-select": vSelect,
      TestimonialSidebar
    },
    data() {
      return {
        searchQuery:'',
        isMounted: false,
        totalDocs: 0,
        currentPage: 1,
        dataTableParams: {
          search: "",
          sort: "fullName",
          dir: "asc",
          page: 1,
          limit: 10
        },
        serverResponded: false,
        contents: [],
        limitOptions: [
          { text: "5", value: 5 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          { text: "100", value: 100 }
        ],
        //add story sidebar
        isSidebarActive: false,
        sidebarData: {},
        dataId:"",
        awaitingSearch: false,
      };
    },
    methods: {
      ...mapActions("setting", [
        "fetchTestimonial",
        "deleteTestimonial"
      ]),
      getTestimonialList() {
        this.dataTableParams.search = this.searchQuery;
        this.fetchTestimonial(this.dataTableParams).then(res => {
          this.contents = res.data.data.docs;
          this.totalDocs = res.data.data.pagination.total;
          this.page = res.data.data.pagination.page;
        });
        this.serverResponded = true;
      },
      handleSearch(searching) {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getTestimonialList();
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.getTestimonialList();
      },
      handleSort(key, active) {
        this.serverResponded = false;
        this.dataTableParams.sort = key;
        this.dataTableParams.dir = active;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getTestimonialList();
      },
      addTestimonial() {
        this.sidebarData = {}
        this.toggleDataSidebar(true)
      },
      editDetailHandler(data, id) {
        this.sidebarData = data;
        this.dataId = id
        this.toggleDataSidebar(true)
      },
      toggleDataSidebar(val = false) {
        this.isSidebarActive = val
      },
      toggleRefreshData(val = false) {
        if (val) {
          this.getTestimonialList();
        }
      },
      deleteHandler(id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.acceptDeleteAlert(id)
          }
        })

      },
      acceptDeleteAlert(id) {
        this.$vs.loading()
        this.deleteTestimonial(id)
          .then(()   => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Success',
              text: "Testimonial Deleted Successfully.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
            this.getTestimonialList();
          })
          .catch(err => {
            this.$vs.loading.close()
          })
      },
    },
    watch: {
      "dataTableParams.page": function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.dataTableParams.page = newVal;
          this.getTestimonialList();
        }
      },
      "dataTableParams.limit": function(newlimit, oldLimit) {
        if (newlimit !== oldLimit) {
          this.serverResponded = false;
          this.dataTableParams.page = 1;
          this.dataTableParams.limit = newlimit;
          this.getTestimonialList();
        }
      },
      searchQuery: function () {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getTestimonialList();
            this.awaitingSearch = false;
          }, 1000); // 1 sec delay
        }
        this.awaitingSearch = true;
      },
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      }
    },
    created() {
      this.getTestimonialList();
    }
  };
</script>
